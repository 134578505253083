import styled from 'styled-components';
export const CollectionBanner = styled.div`
.collection-banner{
  height: 320px;
}
.collection-img-mobile{
  height: 300px; 
}
.collection-img-container{
  position:absolute;
  top: 0;
  width:45%; 
}
.img-reflection{
  height: 50px;
  background-image: linear-gradient(0deg, transparent, white 100%);
  transform: rotateX(180deg);
  opacity: 0.2; 
}
@media (min-width: 1200px) {
  .collection-img-container{
    width:30%; 
  }
}
`;
export const BannerWrapper = styled.div`
@media(max-width:920px){
.view-collection{
width:100%;
}
}
margin-top:0px;
 display: flex;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
    background-position: left top;
    height: 320px;


 
  @media (max-width: 600px) {
  height: 220px;
  background-size: cover;
  background-position: center;

    h1 {
      font-size: 1.5rem;
    }
    .btn {
      padding: 0.3rem 0.5rem !important;
      font-size: 0.9rem;
    }
  }
`;