import Link from "next/link";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { UserContext } from "../../../contexts/UserContext";
import ReactTypingEffect from 'react-typing-effect';
import { tagExplore, tagLearnMore } from "../../../lib/events";
import { Agency } from "../../../models/agency";


export const EventCaption = ({ agencyId, myAgency, agency }: { agencyId?: string, myAgency?: Agency, agency?: Agency }) => {
    const SwalAlert = withReactContent(Swal)
    const { isAuthenticated, openLoginPopup } = useContext(UserContext);
    const router = useRouter();
    const sell = async () => {
        if (!isAuthenticated) {
            const result = await SwalAlert.fire({
                icon: 'info',
                title: 'Almost there',
                html: '<p>Please sign-in to Sell</p>',
                showCancelButton: true,
                confirmButtonText: 'Login',
                confirmButtonColor: "#1478DD",
                footer: '<a href="/help">Why should I login?</a>'
            });
            if (result.isConfirmed) {
                openLoginPopup();
                return;
            } else {
                return;
            }
        } else {
            // router.push(`/sell/new`);
            window.location.href = "https://flywith.fleato.com";
        }
    }
    return (
        <div className="container  text-md-start  py-4 py-md-0">
            <p className="mb-2">{agencyId == 'fresharts' ? 'FRESH ARTS' : agency?.preferences?.marketplacePage?.title1 ?? myAgency?.preferences?.marketplacePage?.title1 ?? ""}</p>
            {agencyId == 'fresharts' ? <h1 >
                CHAMPIONS <br />for Art and Fashion <br />
                <h2 className="text-white-light">HOUSTON <ReactTypingEffect typingDelay={500} eraseDelay={3000} text={["ARTISTS", "AND", "CREATIVES"]} /></h2>
            </h1>
                :
                <h1>
                    {agency?.preferences?.marketplacePage?.title2 ?? myAgency?.preferences?.marketplacePage?.title2 ?? "ART DONE DIFFERENT"}
                    <br />
                    <h2 className="text-white-light"><ReactTypingEffect typingDelay={500} eraseDelay={3000} text={agency?.preferences?.marketplacePage?.title3 ?? myAgency?.preferences?.marketplacePage?.title3 ?? ["CONNECT ART LOVERS", "LOCAL EMERGING TALENT"]} /></h2>
                </h1>}
            <div className="d-flex justify-content-center justify-content-md-start ">
                <Link href={myAgency?.preferences?.exploreLink ? myAgency?.preferences?.exploreLink : `/categories/all?agencyId=${agencyId}`} passHref>
                    <button
                        className="btn blue-bg rounded mt-2 px-4 py-2 text-white me-3"
                        type="button"
                        onClick={() => tagExplore()}
                    >
                        Explore
                    </button>
                </Link>
                <Link href={myAgency?.preferences?.learnMoreLink ? myAgency?.preferences?.learnMoreLink : 'https://services.fleato.com'} passHref>

                    <button
                        className="btn white-bg rounded mt-2 px-3 py-2 border-blue text-blue"
                        type="button"
                        onClick={() => tagLearnMore()}
                    >
                        Learn more
                    </button>
                </Link>
            </div>

        </div>
    )
}