import styled from 'styled-components';

export const BannerWrapper = styled.div`
background:#fff;
p{margin-bottom:5px;}
  display: flex;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
    background-position: right bottom;
    height: 500px;

@media(min-width:720px){
  background-position:100px  bottom;
}
@media(min-width:920px){
  background-position:right  bottom;
}
  @media (max-width: 1199px) {
    height: 430px;
  }

  @media (max-width: 991px) {
    height: 48vh;
  }

  @media (max-width: 768px) {
    height: 50vh;
  }
  @media (width: 768px) {
    height: 42vh;

  }
  @media (max-width: 600px) {
    height: 250px;

    h1 {
      font-size: 1.5rem;
    }
    .btn {
      padding: 0.3rem 0.5rem !important;
      font-size: 0.9rem;
    }
  }
`;