import { useContext } from "react";
import Router from "next/router";
import { ArtistProfile } from "../../models/user";
import { UserContext } from "../../contexts/UserContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export const Caption = ({ artist }: { artist: ArtistProfile }) => {
  const { isAuthenticated, openLoginPopup, firebaseGuestSignIn } = useContext(UserContext);
  const SwalAlert = withReactContent(Swal);
  return (
    <div className="offset-md-8 offset-lg-6 caption text-center text-md-start  my-3 my-md-0" >
      <p className="mb-2">{artist?.storeName ?? artist?.name}</p>
      {artist.tagLines?.length > 0 ? <h1>
        {artist.tagLines?.[0]}
        <br /> {artist.tagLines?.[1]}
      </h1> : <h1>{artist?.storeName}</h1>}
      <div className="d-flex flex-column flex-lg-row align-items-center">
        <button
          className={`btn ${artist.commission ? "btn-ghost-small " : "blue-bg text-white"} col-6 col-lg-5 col-xl-4 rounded px-4 py-2 me-lg-3 view-collection`}
          type="button"
          onClick={() => Router.push(`/m/a/${artist.id}`)}
        >
          View Collection
        </button>
        {/* <button
          className="btn white-bg col-6 col-md-12 col-lg-5 col-xl-4 rounded px-4 py-2 mt-2 mt-lg-0 border-blue text-blue"
          type="button"
          onClick={() => Router.push({ pathname: `/categories/merchandise/`, query: { artist: artist?.id } })}
        >
          Explore Merch
        </button> */}
      </div>
      {artist.commission &&
        <button
          className="btn blue-bg rounded px-4 py-2 mt-2 mt-lg-0 text-white"
          type="button"
          onClick={async () => {
            if (isAuthenticated) {
              Router.push({ pathname: `/p/${artist.commission.productId}`, query: { "customize": true } })
            } else {
              const result = await SwalAlert.fire({
                icon: 'info',
                title: 'Almost there',
                html: '<p>Please sign-in to buy</p>',
                showCancelButton: true,
                // cancelButtonText: "Checkout as Guest",
                confirmButtonText: 'Login',
                confirmButtonColor: "#1478DD",
                footer: '<a href="/help">Why should I login?</a>'
              });
              if (result.isConfirmed) {
                openLoginPopup();
              } else {
                // await firebaseGuestSignIn();
                // Router.push({ pathname: `/p/${artist.commission.productId}`, query: { "customize": true }});
              }
            }
          }}>
          {artist.commission?.tagLine ?? "Commission this Artist"}
        </button>
      }
    </div>
  )


}