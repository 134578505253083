import React from "react";
import { RefinementWrapper } from "../../styles/product-style";
import { LinkRefinement } from "./link-refinement";
import { NumericMenu } from 'react-instantsearch-dom';

export default function Categories({ categoryFilter, setCategoryFilter }: { categoryFilter: string, setCategoryFilter: (s: string) => void }) {
  return (
    <RefinementWrapper>
      {/* <LinkRefinement attribute="categories" limit={10} /> */}
      {/* <LinkRefinement attribute="artist" limit={10} /> */}
      {/* <div className="cursor-pointer"> 
      <h4  className={`${categoryFilter?.length > 0 ? "fw-bold" : ""}`} onClick={() => {
        if(categoryFilter?.length === 0){
          setCategoryFilter("art-and-merch");
        }else {
          setCategoryFilter("");
        }
      }}>Art & Merch</h4> </div> */}

      <LinkRefinement attribute="collection" limit={10} />
      <LinkRefinement attribute="shippingMethods" />
      <h4>Price Range</h4>
      <NumericMenu
        attribute="buyNowPrice"
        items={[
          { label: 'less than $10', end: 10 },
          { label: '$10 to $50', start: 10, end: 50 },
          { label: '$50 to $100', start: 50, end: 100 },
          { label: 'more than $100', start: 100 }
        ]}
      />
      {/* <MinMaxInput attribute="buyNowPrice" /> */}
      <LinkRefinement attribute="condition" />
    </RefinementWrapper>
  );
}
