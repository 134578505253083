import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Refinement from "../refinement/refinement";
import ProductList from "../product-list/product-list";
import { togglePdtFilterSelector, toggleWalletSelector } from "../../store/selectors/common.selectors";
import { AiOutlineClose } from "react-icons/ai";
import { togglePdtFilterAction, toggleWalletAction } from "../../store/slices/common.slice";
import { PdtFilterWrapper } from "../../styles/product-style";
import { CategoriesContext } from "../../contexts/CategoriesContext";
import { Configure } from 'react-instantsearch-dom';
import { useMedia } from "../../hooks/helper/useMedia";
import { useRouter } from "next/router";
import useBranding from "../../hooks/store/useBranding";
import { ArtistProfile } from "../../models/user";
import Router from "next/router";
import { TypesenseProduct } from "../../models/product";
import ArtistProductHits from "./artist-product-hits";

export default function ArtistProducts({ artist, products }: { artist: ArtistProfile, products: TypesenseProduct[] }) {
  const [scrolled, setScrolled] = useState<number>(0);
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const togglePdtFilter = useSelector(togglePdtFilterSelector);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

  })

  const closePdtFilter = () => {

    dispatch(togglePdtFilterAction(!togglePdtFilter));
  };
  const desktop = useMedia("(min-width: 1200px)");
  const handleScroll = () => {
    const offset = window.scrollY;
    if (desktop) {
      if (offset > 422) {
        setScrolled(offset - 200);
      }
      else {
        setScrolled(0);
      }

    }

  }
  const router = useRouter();
  const { facetFiltersWithBranding } = useBranding();
  const { recentDrop, setRecentDrop } = useContext(CategoriesContext)
  useEffect(() => {

    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        // Will run when leaving the current page; on back/forward actions
        // Add your logic here, like toggling the modal state
        // alert('back')
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router])
  return (
    <div className="main-content  d-flex justify-content-center my-5" >
      <div className="w-100">
        {/* <Configure facetFilters={facetFiltersWithBranding(["status:listed", `artist:${artist?.id ?? ""}`])} filters={[`artist:${artist?.id ?? ""}`]} /> */}
        <div className="container max-w-100 white-bg">
          <div className={`row justify-content-center`}>
            <PdtFilterWrapper
              className={`${togglePdtFilter ? "visible" : ""
                } position-absolute w-100 ht-100  d-lg-none p-0`}
            >
              <div className="pdt-wrapper  pt-4 px-3">
                <AiOutlineClose
                  onClick={closePdtFilter}
                  color="#fff"
                  size={24}
                  className="cursor-pointer close"
                />
                <Refinement categoryFilter="" setCategoryFilter={(s: string) => {}} />
              </div>
            </PdtFilterWrapper>
            <div className={`${!showAll ? "recent-drop" : ""} d-lg-flex justify-content-center align-items-start`}>
              {/* <ProductList /> */}
              <div className="col-md-12 p-0 p-md-4 pt-md-0 col-lg-10">
                <ArtistProductHits hits={products} artist={artist.id} />
              </div>
              {!showAll &&
                <div className=" pb-5 d-flex justify-content-center align-self-center">
                  <button
                    onClick={() => { Router.push(`/m/a/${artist.id}`) }}
                    className="btn white-bg rounded mt-2 px-3 py-1 py-md-2 border-blue text-blue"
                    type="button"
                  >
                    Browse All
                  </button>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
