import React, { Component, useCallback, useEffect, useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { TypesenseProduct } from '../../models/product';
import Lottie from 'react-lottie';
import noItem from "../../public/lottie/13525-empty.json";
import { ProductCard } from '../product-list/product-card';


const ArtistProductHits = ({hits, artist}: {hits:TypesenseProduct[], artist: string}) => {

  const [timer, setTimer ] =  useState(0);
  useEffect(()=>{
  let myInterval = setInterval(() => {
          if (timer < 5) {
              setTimer(timer + 1);
          }
          if (timer === 5) {
            clearInterval(myInterval)
          } 
      }, 1000)
      return ()=> {
          clearInterval(myInterval);
        };
  });

  return (
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list m-0 p-0">

          {hits.filter(h => !!artist ? h.artist === artist : true).map(hit => (
            hit?.status === "listed" &&
            <li key={hit.id} className="ais-InfiniteHits-item">
              <ProductCard hit={hit} />
            </li>
          ))}
          {hits.length === 0 && timer === 5 && 
            <div className='col-12 '>
              <h1 className='text-center'>No Item Found!</h1>
              <Lottie heigth={0} width={450} options={{ loop: true, autoplay: true, animationData: noItem }} />
            </div>}

          {hits.length === 0 && timer < 5 &&
            <div className='col-12 '>
              <h1 className='text-center'>Searching Items For You!</h1>
              <Lottie heigth={0} width={450} options={{ loop: true, autoplay: true, animationData: noItem }} />
            </div>}

        </ul>
      </div>
    );
  }

export default ArtistProductHits;
