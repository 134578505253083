import React from "react";
import BannerImg from "../../public/images/new-item-banner.png";
import { BannerWrapper } from "./newItems-style";
import Image from "next/image";
import { useMedia } from "../../hooks/helper/useMedia";
import { Caption } from "./Caption";
import { ArtistProfile } from "../../models/user";
import { useMediaQuery } from 'react-responsive'

export const NewItems = ({ artist }: { artist: ArtistProfile }) => {
  const isPhone = useMediaQuery({ query: '(max-width: 600px)' })
  return (
    <div className="main-content  d-flex justify-content-center">
      <div className="w-100">
        {artist?.bannerImage &&
          <>
            {isPhone ?
              <>
                <BannerWrapper style={{ backgroundImage: `url(${artist.featuredBannerImage ?? artist.bannerImage})` }}>
                </BannerWrapper>
                <BannerWrapper style={{ height: "50px", backgroundImage: `linear-gradient(0deg, transparent, white 100%), url(${artist.featuredBannerImage ?? artist.bannerImage})`, transform: "rotateX(180deg)", opacity: "0.2" }}>
                </BannerWrapper>
                <div className="container text-center text-md-start position-relative">
                  <Caption artist={artist} />
                </div>
              </> : <>
                <BannerWrapper style={{ backgroundImage: `url(${artist.featuredBannerImage ?? artist.bannerImage})` }}>
                  <div className="container text-center text-md-start position-relative">
                    <Caption artist={artist} />
                  </div>
                </BannerWrapper>
              </>
            }
          </>
        }
      </div>
    </div>
  );
};
