import React from "react";
import { BannerWrapper } from "./banner-style";
import BannerImg from "../../../public/images/home-banner.png";
import { Caption } from "./caption";
import { EventCaption } from "./eventCaption";
import { Agency } from "../../../models/agency";
export const Banner = ({ isEvent, agencyId = "fleato", myAgency, agency }: { isEvent: boolean, agencyId?: string, myAgency?: Agency , agency?: Agency}) => {
  return (
    <>
      <div className="d-block  d-md-none">
        <div className="container text-center text-md-start position-relative py-3">
          {isEvent ? <EventCaption agencyId={agencyId} myAgency={myAgency} agency={agency} /> : <Caption agencyId={agencyId} />}
        </div>
        <BannerWrapper
          style={{ backgroundImage: `url(${isEvent ? (agency?.preferences?.bannerImageLink ?? myAgency?.preferences?.bannerImageLink ?? BannerImg.src) : BannerImg.src})` }}
        ></BannerWrapper>
      </div>
      <div className="d-none d-md-block">
        <BannerWrapper style={{ backgroundImage: `url(${isEvent ? (agency?.preferences?.bannerImageLink ?? myAgency?.preferences?.bannerImageLink ?? BannerImg.src) : BannerImg.src})` }}>
          {isEvent ? <EventCaption agencyId={agencyId} agency={agency} /> : <Caption agencyId={agencyId}/>}
        </BannerWrapper>
      </div>
    </>
  );
};
