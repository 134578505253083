import { RefinementList, connectRefinementList } from 'react-instantsearch-dom';
import Link from "next/link";
import { useRouter } from 'next/router';
import { titleCase } from '../../util/util';
import { useEffect, useContext, useState } from 'react';
import { CategoriesContext } from '../../contexts/CategoriesContext';
import { CategoryMapping } from '../../util/category-mapping';
import { SearchContext } from '../../contexts/typesense/SearchContext';

const LinkRefinementComponent = ({ items, refine, createURL, attribute }) => {
  const sorted = items.sort((a, b) => a.label < b.label ? -1 : 1);
  const {searchScope} = useContext(SearchContext);
  const router = useRouter();

  const { recentDrop } = useContext(CategoriesContext);
  const { isEmpty, setIsEmpty } = useContext(CategoriesContext);

  const startSearching = async () => {
    document.body.style.overflow = "auto";
  }

  const [currentRefinements, setCurrentRefineMents] = useState<string[]>([]);

  useEffect(() => {
    (async() => {
      const currentCollection = await router?.query?.collection as string;
      const currentShippingMethod = await router?.query?.shippingmethod as string;
      const currentCondition = await router?.query?.condition as string;
  
      if(currentCollection && !currentRefinements?.find(i => i === currentCollection) && attribute === "collection"){
        await refine(currentCollection);
        await setCurrentRefineMents([...currentRefinements, currentCollection]);
      }
      if(currentShippingMethod && !currentRefinements?.find(i => i === currentShippingMethod) && attribute === "shippingMethods"){
        await refine(currentShippingMethod);
        await setCurrentRefineMents([...currentRefinements, currentShippingMethod]);
      }   
      if(currentCondition && !currentRefinements?.find(i => i === currentCondition) && attribute === "condition"){
        await refine(currentCondition);
        await setCurrentRefineMents([...currentRefinements, currentCondition]);
      }   
      // console.log({currentCollection, currentCondition, currentShippingMethod, currentRefinements, attribute});  
    })()
  }, [router?.query])

  // useEffect(() => {
  //   if (recentDrop && attribute === 'categories') {
  //     if (router.query.categories &&  Object.keys(CategoryMapping).includes(router.query.categories as string)) {
  //       // console.log(searchScope, `$refine - link-refinement (${attribute}) - search term = `, CategoryMapping[router.query.categories as string])
  //       refine(CategoryMapping[router.query.categories as string]);
  //     }
  //   } else {
  //     // console.log(searchScope, `$refine - link-refinement (${attribute}) - clear`)
  //     refine('');
  //   }
  // }, [recentDrop, router.query.categories])

  let currentAttribute = attribute === "categories" ? "Categories" :
                         attribute === "shippingMethods" ? "Delivery Options" : 
                         attribute === "collection" ? "Collection" :
                         attribute === "artist" ? "Artist" :
                         attribute === "condition" ? "Condition" : "";
  // useEffect(() => {
  //   if(currentAttribute?.length === 0 && attribute === "categories") {
  //     currentAttribute = "Categories";
  //   }
  //   if(currentAttribute?.length === 0 && attribute === "shippingMethods") {
  //     currentAttribute = "Delivery Options";
  //   }
  //   if(currentAttribute?.length === 0 && attribute === "condition") {
  //     currentAttribute = "Condition";
  //   }
  // }, [attribute])

  const refineAndUpdateUrl = async(input: string) => {
    const currentCollection = router?.query?.collection;
    const currentShippingMethod = router?.query?.shippingmethod;
    const currentCondition = router?.query?.condition;
    const currentArtist = router?.query?.artist;
    const currentCategory = router?.query?.categories;
    // console.log({currentArtist, currentCategory, currentCollection, currentCondition, currentShippingMethod, attribute, input});
    let path = `/categories/${currentCategory ?? ""}`;
    
    if(currentRefinements?.find(i => i === input)){
      setCurrentRefineMents(currentRefinements?.filter(i => i != input));
    }else {
      setCurrentRefineMents([...currentRefinements, input]);
    }
    ///add artist in url
    if(attribute === "artist") {
      path = path + `?artist=${input}`;
    }
    else if(attribute != "artist" && currentArtist) {
      path = path + `?artist=${currentArtist}`;
    }

    //add collection in url
    if(attribute === "collection" && input?.length > 0) {
      path = path + (currentArtist ? `&&` : `?`) + `collection=${input}`;
    }
    else if(attribute != "collection" && currentCollection) {
      path = path + (currentArtist ? `&&` : `?`) + `collection=${currentCollection}`;
    }

    // add shipping method in url
    if(attribute === "shippingMethods" && input?.length > 0) {
      path = path + ((currentArtist || currentCollection) ? `&&` : `?`) + `shippingmethod=${input}`;
    }
    else if(attribute != "shippingMethods" && currentShippingMethod){
      path = path + ((currentArtist || currentCollection) ? `&&` : `?`) + `shippingmethod=${currentShippingMethod}`;
    }

    //add condition in url
    if(attribute === 'condition' && input?.length > 0) {
      path = path + ((currentArtist || currentCollection || currentShippingMethod) ? "&&" : "?") + `condition=${input}`;
    }
    else if(attribute != "condition" && currentCondition){
      path = path + ((currentArtist || currentCollection || currentShippingMethod) ? "&&" : "?") + `condition=${currentCondition}`;
    }

    router?.push(path);
  }


  return (
    <>      
    {sorted?.length > 0 && <h4>{currentAttribute}</h4> }
    <ul className="list-unstyled ps-2 font-md mt-2 pb-2">
      
      {sorted.map((item) => (
        <li key={item.value} onClick={() => {
          router.query.categories === 'all' ?
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            }) : null
        }}>

          <a href={createURL(item.value)} style={{ fontWeight: item.isRefined ? 'bolder' : 'normal', }} className="no-linespace"
            onClick={event => {
              event.preventDefault();
              if (item?.value?.length === 0) {
                refine(item?.value);
                if(router?.asPath?.includes("/categories/"))
                  refineAndUpdateUrl(item?.value);
              } else {
                refine(item?.value?.[item?.value?.length - 1]);
                if(router?.asPath?.includes("/categories/"))
                  refineAndUpdateUrl(item?.value?.[item?.value?.length - 1]);
              }
              if (router.pathname != "/") {
                startSearching()
              }
            }}>
            {titleCase(item.label)}
            {/* ({item.count}) */}
          </a>

        </li>
      ))}
    </ul>
    </>
  )

}


export const LinkRefinement = connectRefinementList(LinkRefinementComponent);
